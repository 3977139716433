
import { ref } from 'vue';
import { useOrgGroupForm } from '@/composables/useOrgGroupForm';
import { FetchStates } from '@/helpers/fetch-states';
import { useAuth } from '@/composables/useAuth';

export default {
  name: 'OrgGroupForm',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { isVAR, isSuperAdmin } = useAuth();
    const formRef = ref(null);
    const id = ref(props.id);

    const orgGroupFormLogic = useOrgGroupForm(id, formRef);

    const saveOrgGroup = async () => {
      await orgGroupFormLogic.dispatchSubmit();
    };

    const removeContact = (n) => {
      orgGroupFormLogic.form.value.contacts.splice(n - 1, 1);
      orgGroupFormLogic.contactCount.value = orgGroupFormLogic.form.value.contacts.length;
    };

    return {
      isVAR,
      isSuperAdmin,
      formRef,
      ...orgGroupFormLogic,
      saveOrgGroup,
      removeContact,
      FetchStates,
    };
  },
};
