import { fetchSingleOrg } from '@/api/accidentplan-api';
import { SingleOrgDTO } from '@/api/dto/org';
import { alertError } from '@/helpers/alert-error';
import { FetchStates } from '@/helpers/fetch-states';
import router from '@/router';
import {
  computed,
  inject,
  InjectionKey,
  provide,
  readonly,
  ref,
  Ref,
  watch,
} from 'vue';

const OrganizationInfoSymbol: InjectionKey<Ref<SingleOrgDTO | null>> = Symbol();

export function useOrgId() {
  return computed(() =>
    parseInt(router.currentRoute.value.params['id'] as string, 10),
  );
}

export function useSingleOrg(id: Ref<number>) {
  const org = ref<SingleOrgDTO | null>(null);
  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);

  provide(OrganizationInfoSymbol, readonly(org));

  const fetchOrg = async () => {
    if (!id.value) return;

    fetchState.value = FetchStates.FETCHING;

    try {
      org.value = await fetchSingleOrg(id.value);
      fetchState.value = FetchStates.FETCHED;
    } catch (err) {
      console.error(err);
      fetchState.value = FetchStates.UNFETCHED;
      alertError(err, 'There was an error fetching the organization.');
    }
  };

  watch(id, fetchOrg);

  fetchOrg();

  return { org, fetchState, fetchOrg };
}

export function useSingleOrgChild() {
  const org = inject(OrganizationInfoSymbol);

  return { org };
}
