
import { useOrgId } from '@/composables/useSingleOrg';
import { defineComponent } from '@vue/runtime-core';
import { useOrgLicensing } from '@/composables/useOrgLicensing';
import { FetchStates } from '@/helpers/fetch-states';

export default defineComponent({
  name: 'OrgLicensingComp',
  setup() {
    const orgId = useOrgId();
    const orgLicensing = useOrgLicensing(orgId);

    return {
      orgId,
      ...orgLicensing,
      FetchStates,
    };
  },
  computed: {
    licPercentage() {
      if (!this.activeCount || !this.maxCount) {
        return 0;
      }

      const percentage = Math.round((100 * this.activeCount) / this.maxCount);
      if (!percentage) {
        return 0;
      }
      return percentage;
    },
  },
  methods: {
    format() {
      if (!this.activeCount) {
        return '';
      }
      return `${this.activeCount}/${this.maxCount} licenses used`;
    },
  },
});
