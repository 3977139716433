import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "org-view-comp" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_page_header = _resolveComponent("el-page-header")
  const _component_org_form = _resolveComponent("org-form")
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_org_notifications = _resolveComponent("org-notifications")
  const _component_org_group_form = _resolveComponent("org-group-form")
  const _component_integrations_page = _resolveComponent("integrations-page")
  const _component_el_tabs = _resolveComponent("el-tabs")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _withDirectives(_createVNode("div", null, [
      ($setup.isVAR || $setup.isSuperAdmin)
        ? (_openBlock(), _createBlock(_component_el_page_header, {
            key: 0,
            class: "page-header",
            onBack: $setup.routeBack,
            content: _ctx.org?.name
          }, null, 8, ["onBack", "content"]))
        : (_openBlock(), _createBlock("h3", _hoisted_2, _toDisplayString(_ctx.org?.name), 1)),
      (_ctx.fetchState === $setup.FetchStates.FETCHED)
        ? (_openBlock(), _createBlock(_component_el_tabs, {
            key: 2,
            modelValue: $setup.activeTab,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ($setup.activeTab = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tab_pane, {
                label: "Organization Info",
                name: "info"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_org_form)
                ]),
                _: 1
              }),
              _createVNode(_component_el_tab_pane, {
                label: "Notifications",
                name: "notifications"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_org_notifications)
                ]),
                _: 1
              }),
              (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.account.organization_groups, (item, index) => {
                return (_openBlock(), _createBlock(_component_el_tab_pane, {
                  label: item,
                  name: 'group_info_' + index
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_org_group_form, {
                      id: $setup.account.org_groups[index]
                    }, null, 8, ["id"])
                  ]),
                  _: 2
                }, 1032, ["label", "name"]))
              }), 256)),
              ($setup.isVAR || $setup.isSuperAdmin)
                ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                    key: 0,
                    label: "Integrations",
                    name: "integrations"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_integrations_page, { org: _ctx.org }, null, 8, ["org"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ], 512), [
      [_directive_loading, _ctx.fetchState !== $setup.FetchStates.FETCHED]
    ])
  ]))
}