import {
  fetchActiveUserCountInOrg,
  fetchSingleOrg,
} from '@/api/accidentplan-api';
import { alertError } from '@/helpers/alert-error';
import { SortDirection } from '@/helpers/data-query';
import { FetchStates } from '@/helpers/fetch-states';
import { Ref, ref, watch } from 'vue';

export function useOrgLicensing(orgId: Ref<number>) {
  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);

  const activeCount = ref<number | undefined>(undefined);
  const maxCount = ref<number | undefined>(undefined);

  const fetchOrgCount = async () => {
    if (!orgId.value) {
      return;
    }

    fetchState.value = FetchStates.FETCHING;

    try {
      // getting the active user count
      const orgActivations = await fetchActiveUserCountInOrg(orgId.value);

      activeCount.value = orgActivations;

      // getting the maximum activation count
      const maxActivations = await fetchSingleOrg(orgId.value);

      maxCount.value = parseInt(maxActivations.activations);

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);
      alertError(error, 'Failed to fetch activations!');

      fetchState.value = FetchStates.UNFETCHED;
    }
  };

  fetchOrgCount();
  watch(orgId, fetchOrgCount);

  return {
    fetchState,
    activeCount,
    maxCount,
    fetchOrgCount,
  };
}
