
import { ref, computed } from 'vue';
import router from '@/router';
import { useSingleOrg, useOrgId } from '@/composables/useSingleOrg';
import { FetchStates } from '@/helpers/fetch-states';
import { useAuth } from '@/composables/useAuth';
import OrgForm from '@/components/orgs/OrgForm.vue';
import OrgGroupForm from '@/components/orgs/OrgGroupForm.vue';
import OrgBilling from '@/components/orgs/OrgLicensing.vue';
import OrgNotifications from '@/components/orgs/OrgNotifications.vue';
import IntegrationsPage from '@/components/orgs/integration_config/OrgIntegrationsMain.vue';

export default {
  name: 'OrgViewComponent',
  setup() {
    const activeTab = ref('info');
    const { isVAR, isSuperAdmin, account } = useAuth();

    const orgId = useOrgId();
    const orgInfo = useSingleOrg(orgId);

    const routeToOrganizations = () =>
      router.push({ name: 'OrganizationsPage' });

    const routeBack = () => {
      if (window.history.state.back === null) {
        routeToOrganizations();
      } else {
        router.back();
      }
    };

    return {
      account,
      isVAR,
      isSuperAdmin,
      activeTab,
      routeToOrganizations,
      routeBack,
      ...orgInfo,
      FetchStates,
    };
  },
  components: {
    OrgForm,
    OrgGroupForm,
    OrgBilling,
    OrgNotifications,
    IntegrationsPage,
  },
};
