import { createSingleOrg, updateSingleOrg } from '@/api/accidentplan-api';
import { CreateSingleOrgDTO, UpdateSingleOrgDTO } from '@/api/dto/org';
import { alertError } from '@/helpers/alert-error';
import { FetchStates } from '@/helpers/fetch-states';
import { OrganizationsModule } from '@/store/organizations';
import { ElMessage } from 'element-plus';
import { ref, Ref, watch } from 'vue';
import { useSingleOrg } from './useSingleOrg';
import { useTemplateList } from './useTemplateList';

export function useOrgForm(
  id: Ref<number>,
  isEditing: Ref<boolean>,
  formRef: Ref<any>,
) {
  const orgInfo = useSingleOrg(id);
  const { templateList, templateFetchState } = useTemplateList();

  const form = ref<UpdateSingleOrgDTO>({
    id: id.value,
    name: '',
    templates_id: undefined,
    address: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    main_phone: '',
    us_dot_num: '',
  });

  watch(orgInfo.org, org => {
    if (org) form.value = org;
  });

  const rules = {
    name: [{ required: true, message: 'Name is required', trigger: 'blur' }],
    // address: [
    //   { required: true, message: 'Address is required', trigger: 'blur' },
    // ],
    // city: [{ required: true, message: 'City is required', trigger: 'blur' }],
    // state: [{ required: true, message: 'State is required', trigger: 'blur' }],
    // country: [
    //   { required: true, message: 'Country is required', trigger: 'blur' },
    // ],
    // zip: [{ required: true, message: 'Zip code is required', trigger: 'blur' }],
    // main_phone: [
    //   {
    //     required: true,
    //     message: 'Main phone number is required',
    //     trigger: 'blur',
    //   },
    // ],
    // us_dot_num: [
    //   { required: true, message: 'US DOT # is required', trigger: 'blur' },
    // ],
  };

  const saveState = ref<FetchStates>(FetchStates.UNFETCHED);

  const dispatchSubmit = async () => {
    const isValid = await new Promise<boolean>(res =>
      formRef.value.validate((_isValid: boolean) => res(_isValid)),
    );

    if (!isValid) return;

    saveState.value = FetchStates.FETCHING;

    if (isEditing.value) {
      try {
        await updateSingleOrg(form.value);
        saveState.value = FetchStates.FETCHED;
        ElMessage.success('Successfully saved organization.');
      } catch (err) {
        console.error(err);
        alertError(err, 'There was an error updating the organization.');
        saveState.value = FetchStates.UNFETCHED;
      }
    } else {
      try {
        await createSingleOrg(form.value as CreateSingleOrgDTO);
        saveState.value = FetchStates.FETCHED;
        ElMessage.success('Successfully created organization.');

        await OrganizationsModule.reFetchOrgs();
      } catch (err) {
        console.error(err);
        alertError(err, 'There was an error creating the organization.');
        saveState.value = FetchStates.UNFETCHED;
      }
    }
  };

  return {
    rules,
    form,
    dispatchSubmit,
    saveState,
    templateList,
    templateFetchState,
  };
}
