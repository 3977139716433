import {
  addOrgContacts,
  deleteOrgContacts,
  getOrgContacts,
} from '@/api/accidentplan-api';
import { AddOrgsContactDTO, OrgsContact } from '@/api/dto/org';
import { alertError } from '@/helpers/alert-error';
import { FetchStates } from '@/helpers/fetch-states';
import { computed, reactive, ref, Ref, watch } from 'vue';
import Papa, { ParseResult } from 'papaparse';
import { UploadFile } from 'element-plus/lib/el-upload/src/upload.type';

export function useOrgNotifForm(formRef: Ref<any>, orgId: Ref<number>) {
  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);
  const saveState = ref<FetchStates>(FetchStates.UNFETCHED);
  const deleteState = ref<FetchStates>(FetchStates.UNFETCHED);

  const contacts = ref<OrgsContact[]>([]);

  const contactSelection = ref<OrgsContact[]>([]);

  const selectedFileRef: Ref<File | undefined> = ref(undefined);
  const selectedFile = computed(() => selectedFileRef.value);
  const fileList = ref<UploadFile[]>([]);
  const uploadState = ref<FetchStates>(FetchStates.UNFETCHED);

  const form = reactive({
    name: '',
    phone_email: '',
  });

  const rules = {
    name: [{ required: true, message: 'Name is required', trigger: 'blur' }],
    phone_email: [
      { required: true, message: 'Email is required', trigger: 'blur' },
    ],
  };

  const clearForm = () => {
    form.name = '';
    form.phone_email = '';
  };

  const fetchContacts = async () => {
    fetchState.value = FetchStates.FETCHING;

    try {
      contacts.value = await getOrgContacts(orgId.value);

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);
      alertError(error, 'There was an error fetching contacts');

      fetchState.value = FetchStates.UNFETCHED;
    }
  };

  const createContact = async () => {
    // validate the form
    const isValid = await new Promise<boolean>(res =>
      formRef.value.validate((_isValid: boolean) => res(_isValid)),
    );
    if (!isValid) return;

    saveState.value = FetchStates.FETCHING;

    try {
      // add the new org contact
      contacts.value = await addOrgContacts(orgId.value, [
        { name: form.name, email: form.phone_email },
      ]);
      clearForm();

      saveState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);
      alertError(error, 'There was an error saving the new contact.');

      saveState.value = FetchStates.UNFETCHED;
    }
  };

  const deleteContacts = async () => {
    deleteState.value = FetchStates.FETCHING;

    try {
      // find which ids to delete
      const idsToDelete = contactSelection.value.map(contact => contact.id);

      // delete those ids
      await deleteOrgContacts(idsToDelete);
      contactSelection.value = [];

      // re-fetch org contacts
      await fetchContacts();

      deleteState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);
      alertError(error, 'There was an error deleting the contacts.');

      deleteState.value = FetchStates.UNFETCHED;
    }
  };

  const selectionChangeHandler = (selection: OrgsContact[]) => {
    contactSelection.value = selection;
  };

  watch(orgId, fetchContacts);

  fetchContacts();

  const fileSelected = (file: UploadFile) => {
    // making sure the file to process is under 5MB
    const fileSizeLimit = 5 * 1024 * 1024;
    console.log('file size: ' + file.size);
    if (file.size > fileSizeLimit) {
      console.error('File upload exceeds maximum size of 5MB.');
      alertError(
        'File upload exceeds maximum size of 5MB.',
        'File upload exceeds maximum size of 5MB.',
      );

      selectedFileRef.value = undefined;
      fileList.value = [];
      return;
    }

    // setting the selected file
    selectedFileRef.value = new File([file.raw], 'users.csv', {
      type: 'text/csv',
    });

    fileList.value = [file];
  };

  const fileRemoved = () => {
    selectedFileRef.value = undefined;

    fileList.value = [];
  };

  const fileUpload = async (addContactsObj: ParseResult<AddOrgsContactDTO>) => {
    uploadState.value = FetchStates.FETCHING;

    try {
      // making sure we have the right fields in our csv
      const allFields = addContactsObj.meta.fields;
      const missingFields = ['name', 'email'].filter(
        field => !allFields?.includes(field),
      );
      if (missingFields.length > 0) {
        throw {
          statusCode: 400,
          message: `The given CSV is missing the following fields: ${missingFields.join(
            ',',
          )}`,
        };
      }

      // uploading the contacts
      contacts.value = await addOrgContacts(orgId.value, addContactsObj.data);

      uploadState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);
      alertError(error, 'There was an error saving the new contacts.');

      uploadState.value = FetchStates.UNFETCHED;
    }

    fileRemoved();
  };

  const fileSubmit = () => {
    if (!selectedFileRef.value) {
      return;
    }

    // parsing the file into a csv, submitting
    Papa.parse<AddOrgsContactDTO>(selectedFileRef.value, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: false,
      complete: fileUpload,
    });
  };

  return {
    fetchState,
    saveState,
    deleteState,
    contacts,
    contactSelection,
    selectedFileRef,
    selectedFile,
    fileList,
    uploadState,
    form,
    rules,
    clearForm,
    fetchContacts,
    createContact,
    deleteContacts,
    selectionChangeHandler,
    fileSelected,
    fileRemoved,
    fileUpload,
    fileSubmit,
  };
}
