
import { computed, ref } from 'vue';
import router from '@/router';
import { useOrgId } from '@/composables/useSingleOrg';
import { useOrgForm } from '@/composables/useOrgForm';
import { FetchStates } from '@/helpers/fetch-states';
import { useAuth } from '@/composables/useAuth';

export default {
  name: 'OrgForm',
  setup() {
    const { isVAR, isSuperAdmin, account } = useAuth();

    const isEditing = computed(() =>
      router.currentRoute.value.path.includes('/organizations/edit'),
    );
    const isReadOnly = computed(() => account.value.org_groups.length);
    const orgId = useOrgId();
    const formRef = ref(null);

    const orgFormLogic = useOrgForm(orgId, isEditing, formRef);

    const routeToOrgs = () => router.push({ name: 'OrganizationsPage' });

    const routeBack = () => {
      if (window.history.state.back === null) {
        routeToOrgs();
      } else {
        router.back();
      }
    };

    const saveOrg = async () => {
      await orgFormLogic.dispatchSubmit();

      if (!isEditing.value) router.push({ name: 'OrganizationsPage' });
    };

    return {
      isVAR,
      isSuperAdmin,
      isEditing,
      isReadOnly,
      formRef,
      ...orgFormLogic,
      routeToOrgs,
      routeBack,
      saveOrg,
      FetchStates,
    };
  },
};
