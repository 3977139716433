<template>
  <div class="integrations-main-page">
    <h1 class="main-header">Integrations</h1>
    <IntegrationsList
      v-if="!currentIntegration"
      :org="org"
      @configureClicked="setActiveComponent"
    />
    <div v-else>
      <el-page-header
        class="vertical-margin"
        @back="goBack"
        :content="currentIntegration"
      >
      </el-page-header>
      <component :is="currentIntegrationComponent" :org="org"></component>
    </div>
  </div>
</template>

<script>
import IntegrationsList from './IntegrationsList.vue';
import { defineAsyncComponent } from 'vue';

const GeotabConfigPage = defineAsyncComponent(() =>
  import(/* webpackChunkName: "GeotabConfig" */ './GeotabConfig.vue'),
);

const configComponents = {
  geotab: GeotabConfigPage,
};

export default {
  name: 'IntegrationsMainPage',
  props: {
    org: {
      type: Object,
      required: true,
    },
  },
  components: {
    IntegrationsList,
  },
  data() {
    return {
      currentIntegrationComponent: null,
      currentIntegration: null,
    };
  },
  methods: {
    setActiveComponent(integration) {
      this.currentIntegration = integration;
      this.currentIntegrationComponent = configComponents[integration];
    },
    goBack() {
      this.currentIntegration = null;
      this.currentIntegrationComponent = null;
    },
  },
};
</script>

<style>
.main-header {
  font-size: 16px;
}
</style>
