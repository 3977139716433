
import { useOrgNotifForm } from '@/composables/useOrgNotifForm';
import { defineComponent } from '@vue/runtime-core';
import { ref } from 'vue';
import { FetchStates } from '@/helpers/fetch-states';
import { useOrgId } from '@/composables/useSingleOrg';

export default defineComponent({
  name: 'OrgNotifications',
  setup() {
    const formRef = ref(null);
    const orgId = useOrgId();
    const orgNotifForm = useOrgNotifForm(formRef, orgId);

    return {
      formRef,
      orgId,
      ...orgNotifForm,
      FetchStates,
    };
  },
  methods: {
    formKeyPress(event: KeyboardEvent) {
      if (event && event.key === 'Enter') {
        this.createContact();
      }
    },
  },
});
