import { fetchSingleOrgGroup } from '@/api/accidentplan-api';
import { SingleOrgGroupDTO } from '@/api/dto/org';
import { alertError } from '@/helpers/alert-error';
import { FetchStates } from '@/helpers/fetch-states';
import {
  ref,
  Ref,
  watch,
} from 'vue';

export function useSingleOrgGroup(id: Ref<number>) {
  const group = ref<SingleOrgGroupDTO | null>(null);
  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);

  const fetchOrgGroup = async () => {
    if (!id.value) return;

    fetchState.value = FetchStates.FETCHING;

    try {
      group.value = await fetchSingleOrgGroup(id.value);
      fetchState.value = FetchStates.FETCHED;
    } catch (err) {
      console.error(err);
      fetchState.value = FetchStates.UNFETCHED;
      alertError(err, 'There was an error fetching the organization group.');
    }
  };

  watch(id, fetchOrgGroup);

  fetchOrgGroup();

  return { group, fetchState, fetchOrgGroup };
}
